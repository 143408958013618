// REACT
import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

// CSS
import './App.css';


// Otros componentes
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import ResetPass from './pages/reset-pass/ResetPass';
import OrderDetails from './pages/order-details/OrderDetails';
import Users from './pages/users/Users';
import ActionLog from './pages/action-log/Action-log';
import UserDetails from './pages/user-details/UserDetails';
import PhotoUpload from './pages/photo-upload/PhotoUpload';
import PendingPhotos from './pages/pending-photos/PendingPhotos';

// Material UI
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

function App() {

  const [ name, setName ] = React.useState( "John Doe" );
  const [ view, setView ] = React.useState( true );
  const [ showViewSwitch, setShowViewSwitch ] = React.useState( false );
  const [ badgeNumber, setBadgeNumber ] = React.useState( 0 );

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#ff8855',
        main: '#0091D0',
        dark: '#ff8855',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#ffffff',
        contrastText: '#000',
      },
      // type: view? 'light' : 'dark',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout name={ name } badgeNumber={ badgeNumber } setName={ setName } view={ view } setView={ setView } showViewSwitch={showViewSwitch}>
          <Switch>
            {/*<Route exact path="/" component={Home} />*/}
            <Route exact path="/" render={ (props) => <Home {...props} setName={setName} view={ view } setShowViewSwitch={setShowViewSwitch} setBadgeNumber={ setBadgeNumber }/> } />
            <Route exact path="/login" render={ (props) => <Login {...props} setName={setName} /> } />
            <Route exact path="/resetpass" render={ (props) => <ResetPass {...props} /> } />
            <Route exact path="/order/:orderId" render={ (props) => <OrderDetails {...props} setName={setName} setBadgeNumber={ setBadgeNumber } setShowViewSwitch={setShowViewSwitch}/> } />
            <Route exact path="/user" render={ (props) => <UserDetails {...props} setName={setName} setShowViewSwitch={setShowViewSwitch}/> } />
            <Route exact path="/users" render={ (props) => <Users {...props} setName={setName} setShowViewSwitch={setShowViewSwitch}/> } />
            <Route exact path="/event-log" render={ (props) => <ActionLog {...props} setName={setName} setShowViewSwitch={setShowViewSwitch}/> } />
            <Route exact path="/upload" render={ (props) => <PhotoUpload {...props} setName={setName} setShowViewSwitch={setShowViewSwitch}/> } />
            <Route exact path="/revision" render={ (props) => <PendingPhotos {...props} setName={setName} setBadgeNumber={ setBadgeNumber } setShowViewSwitch={setShowViewSwitch}/> } />
          </Switch>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );

}

export default App;
