import './OrderCard.css';

import api from "../../services/api";
import lang from "../../services/lang";
import {Link} from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

function OrderCard ( props ) {
    return (
        <div className="order-card">
        
            <div className="catalog-img">
                { props.photo.path &&
                    <Link to={"/order/"+props.photo.serial_num} className="" target="_blank">
                        <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/" + props.photo.path} />
                    </Link> 
                }
                { !props.photo.path && <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/default.png" } />}
            </div>

            <div className="catalog-info">

                <div>
                    <p><span className="catalog-info-span">{lang.getLabel("cliente")}</span> { props.photo.client_id ? props.photo.client_id : "---" }</p>
                    <p><span className="catalog-info-span">{lang.getLabel("num_serie")}</span> { props.photo.serial_num ? props.photo.serial_num : "---" }</p>
                </div>

                <div className="only-desktop-block">
                    <p><span className="catalog-info-span">{lang.getLabel("localizacion")}</span> { (props.photo.order_location ? props.photo.order_location : "---") + " / " + (props.photo.provincia ? props.photo.provincia : "---") }</p>
                    <p><span className="catalog-info-span">{lang.getLabel("referencia")}</span> { props.photo.ref_pedido ? props.photo.ref_pedido : "---" }</p>
                </div>

                <div className="final-info-block">
                    <p><span className="catalog-info-span">{lang.getLabel("descripcion")}</span> { props.photo.order_desc ? props.photo.order_desc : "---" }</p>
                    <p>{props.photo ? <Link to={"/order/"+props.photo.serial_num} className="btn btn-primary ver-mas-btn" target="_blank"><VisibilityIcon style={{ fontSize: 18, position: "relative", top: "3px", left: "-2px" }} /> {lang.getLabel("ver_mas")}</Link> : <span></span>}</p>
                </div>

            </div>

        </div>
    );
}

export default OrderCard;