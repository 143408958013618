const removeData = () => {
    localStorage.removeItem("exkal-token");
    localStorage.removeItem("exkal-username");
    localStorage.removeItem("exkal-user-id");
    localStorage.removeItem("exkal-frontadmin");
    localStorage.removeItem("exkal-lang");
}

const storage = {
    token: {

        get() {
            if ( localStorage.getItem("exkal-token") )
                return localStorage.getItem("exkal-token");
            else
                return null;
        },

        set( token ) {
            localStorage.setItem("exkal-token", token);
        },
        
        remove() {
            removeData();
        },
        
    },
    user: {

        getName() {
            if ( localStorage.getItem("exkal-username") )
                return localStorage.getItem("exkal-username");
            else
                return null;
        },

        setName( name ) {
            localStorage.setItem("exkal-username", name);
        },

        getUserID( id ) {
            if ( localStorage.getItem("exkal-user-id") )
                return localStorage.getItem("exkal-user-id");
            else
                return null;
        },

        setUserID( id ) {
            localStorage.setItem("exkal-user-id", id);
        },

        getEmail() {
            if ( localStorage.getItem("exkal-useremail") )
                return localStorage.getItem("exkal-useremail");
            else
                return null;
        },

        setEmail( email ) {
            localStorage.setItem("exkal-useremail", email);
        },

        getAdmin() {
            if ( localStorage.getItem("exkal-frontadmin") )
                return localStorage.getItem("exkal-frontadmin");
            else
                return null;
        },

        setAdmin( admin ) {
            localStorage.setItem("exkal-frontadmin", admin);
        },

        getLang() {
            if ( localStorage.getItem("exkal-lang") )
                return localStorage.getItem("exkal-lang");
            else
                return "es";
        },

        setLang( lang ) {
            localStorage.setItem("exkal-lang", lang);
        },

        getTmpPass() {
            if ( localStorage.getItem("exkal-user-tmp-pass") )
                return localStorage.getItem("exkal-user-tmp-pass");
            else
                return null;
        },

        setTmpPass( tmp_pass ) {
            localStorage.setItem("exkal-user-tmp-pass", tmp_pass);
        },

        deleteTmpPass() {
            localStorage.setItem("exkal-user-tmp-pass", "");
        },
        
    },
};

export default storage;