// REACT
import React from "react";

// CSS
import "./Login.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";

// Otros componentes
import Loader from '../../components/loader/Loader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


class Login extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            userName: "",
            userPass: "",
            userNameHelper: "",
            userPassHelper: "",
            formWarning: "",
        };

    }

    componentDidMount() {

        // console.log( "Montando componente Login!" );

        // Comprobamos si ya existe un token, y en tal caso redirigimos a la home
        if ( storage.token.get() )
            this.props.history.push("/");

    }


    handleChange = (e) => {

        this.setState({
            // Con las siguientes líneas mantenemos todos los valores que había en form, y añadimos o sobreescribimos uno nuevo
            ...this.state,
            [e.target.name]: e.target.value
        });

        if (this.state.userName.length > 0) {
            this.setState({ userNameHelper: "" });
        }

        if (this.state.userPass.length > 0) {
            this.setState({ userPassHelper: "" });
        }

    }

    handleForgotPass = (e) => {

        alert("Si ha olvidado su contraseña, por favor contacte con:\n yaiza.morillas@exkalsa.com\n\nIf you have forgotten your password, please contact:\n yaiza.morillas@exkalsa.com");

    }


    handleSubmit = (e) => {

        e.preventDefault();

        if ( (this.state.userName.length > 0) && (this.state.userPass.length > 0) ) {

            this.getToken();

        }
        else {

            if (this.state.userName.length == 0) {
                this.setState({ userNameHelper: "Debes completar este campo." });
            }

            if (this.state.userPass.length == 0) {
                this.setState({ userPassHelper: "Debes completar este campo." });
            }

        }

    }

    getToken = async ( ) => {

        this.setState({ loading: true, error: null });

        try {

            let response = await api.users.getToken( {name: this.state.userName, pass: this.state.userPass} );

            if ( response.code != 200 ) {
                this.setState({ formWarning: response.data.error_message  });
            }
            else {
                this.setState({ formWarning: "" });

                // Redirigir a cambio de contraseña si usuario no verificado, almacenando la contraseña temporalmente
                if ( response.data.user.verificado == "0" ) {

                    // Registramos en el localStorage, temporalmente, username y pass
                    storage.user.setName( this.state.userName );
                    storage.user.setTmpPass( this.state.userPass );
                    this.props.history.push("/resetpass");

                }
                else {
                    // Aplicar token en localstorage a través del servicio de storage creado
                    storage.token.set( response.data.token );
                    storage.user.setName( response.data.user.name );
                    storage.user.setEmail( response.data.user.email );
                    storage.user.setAdmin( response.data.user.admin );
                    storage.user.setUserID( response.data.user.id );

                    // Redirigir a Home
                    this.props.history.push("/");
                }

            }

            this.setState({ loading: false, error: null, data: response });
        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

    }


    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="login-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        return (
            <React.Fragment>

                <div className="login_page">

                    <div className="login_page_inner">

                        <div className="own-spacer-md"></div>

                        <div id="login-form-box">
                            <div id="login-form-box-inner">
                            
                                <h2>Formulario de login</h2>

                                <div className="own-spacer-md"></div>

                                <form className="login-form" noValidate autoComplete="off" onSubmit={ this.handleSubmit }>

                                    <TextField
                                        id="outlined-basic"
                                        name="userName"
                                        label="Usuario o Email"
                                        variant="outlined"
                                        error={this.state.userNameHelper.length > 0}
                                        helperText={this.state.userNameHelper}
                                        value={this.state.userName}
                                        onChange={this.handleChange}
                                    />

                                    <div className="own-spacer-xs"></div>

                                    <TextField
                                        id="filled-password-input"
                                        name="userPass"
                                        label="Contraseña"
                                        type="password"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        error={this.state.userPassHelper.length > 0}
                                        helperText={this.state.userPassHelper}
                                        value={this.state.userPass}
                                        onChange={this.handleChange}
                                    />

                                    <div className="own-spacer-xs"></div>

                                    <Button type="submit" variant="contained" size="large" color="primary" >Enviar</Button>

                                    { (this.state.formWarning.length > 0) && <div id="form-warning">{this.state.formWarning}</div> }

                                </form>

                                <button className="forgot-pass" onClick={this.handleForgotPass}>¿Olvidaste la contraseña?</button>

                            </div>    
                        </div>

                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default Login;
