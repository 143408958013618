// REACT
import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

// Componentes
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from '../../components/loader/Loader';

// Servicios
import lang from "../../services/lang";
import api from "../../services/api";

// CSS
import "./NuevoUsuario.css";

function NuevoUsuario(props) {

    const [email, setEmail] = React.useState('');
    const [waitingResponse, setWaitingResponse] = React.useState(false);

    const handleChange = (event) => {
        setEmail(event.target.value);
        // console.log(event.target.value);
    };

    const handleSubmit = async (event) => {

        // Realizamos validación del email (mostrando feedback mediante snackbar)
        if ( validateEmail( email ) ) {
            crearUsuario( email );
        }
        else {
            alert( "El mail no es válido." );
        }

    };

    const handleKeySubmit = (event) => {
        if(event.keyCode == 13){
            handleSubmit();
        }
    }

    const validateEmail = (email) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }

    const crearUsuario = async ( e ) => {

        try {

            // Ponemos estado de carga
            setWaitingResponse( true );

            // Llamámos al método de aprobación de imágenes de la API
            let response = await api.users.create( email );

            // console.log( "Respuesta aprobación: ", response );

            if (response.code == 200) {
                props.showSnackbar( lang.getLabel("users_newuser_ok") );
                // refrescar lista de usaurios?
            }
            else
                props.showSnackbar( lang.getLabel("users_newuser_no") );

        }
        catch (e) {
            props.showSnackbar( lang.getLabel("users_newuser_no2") );
        }

        // Ponemos estado de carga en false
        setWaitingResponse( false );

    }

    return (
        <React.Fragment>
            <TextField
                id="new-user"
                label={lang.getLabel("users_nuevo")}
                value={email}
                onChange={handleChange}
                onKeyDown={handleKeySubmit}
                variant="outlined"
                InputProps={{endAdornment: <IconButton edge="end" color="primary" onClick={handleSubmit}>
                        <AddIcon />
                    </IconButton>}
                }
            />
            { waitingResponse && <Loader /> }
        </React.Fragment>
        
    )
}

export default NuevoUsuario;