// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./UserDetails.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';


class UserDetails extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            lang: "",
        };

    }

    selectedDate = new Date();
    // Creamos una señal de control para poder cancelar el Fetch
    controller = new AbortController();
    signal = this.controller.signal;


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        this.setState( {lang: lang.getLang()} )

        // Si disponemos de un token, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            // Do nothing

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    componentWillUnmount() {
        // Cancelamos el fetch (el de listado de fotografías)
        this.controller.abort();
    }

    handleLangSwitch = ( event ) => {

        this.setState({ lang: event.target.value });

        lang.setLang( event.target.value );

    }


    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>

                    <div className="user-details-page">

                        <Paper className="paper-card">

                            <h2 className="perfil-title">{lang.getLabel("perfil_titulo")}</h2>

                            <Avatar className="avatar-img" alt={ storage.user.getName().toUpperCase() } src="/static/images/avatar/1.jpg" />

                            <div className="own-spacer-sm"></div>

                            <p><span className="user-details-page-label">{lang.getLabel("perfil_nombre")}: </span>{storage.user.getName()}</p>

                            <p><span className="user-details-page-label">{lang.getLabel("perfil_email")}: </span>{storage.user.getEmail()}</p>

                            <p style={{"margin-bottom" : "25px"}}><span className="user-details-page-label">{lang.getLabel("perfil_rol")}: </span>{ storage.user.getAdmin() == "0" ? lang.getLabel("perfil_rol_admin") : lang.getLabel("perfil_rol_invitado") }</p>

                            <FormControl>
                                <InputLabel shrink htmlFor="age-native-label-placeholder">
                                    {lang.getLabel("perfil_idioma")}
                                </InputLabel>
                                <NativeSelect
                                    value={this.state.lang}
                                    onChange={this.handleLangSwitch}
                                >
                                    <option value={0}>{lang.getLabel("perfil_spanish")}</option>
                                    <option value={1}>{lang.getLabel("perfil_english")}</option>
                                    <option value={2}>{lang.getLabel("perfil_french")}</option>
                                </NativeSelect>
                                <FormHelperText>{lang.getLabel("perfil_idioma_tip")}</FormHelperText>
                            </FormControl>

                        </Paper>

                    </div>
    
                </React.Fragment>
            );

        }
        
    }
}

export default UserDetails;
