// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./Users.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import NuevoUsuario from '../../components/nuevo-usuario/NuevoUsuario';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backspace from '@material-ui/icons/Backspace';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

class Users extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            nameFilter: "",
            showSnackbar: false,
            snackbarMessage: "",
        };

    }

    // Método para la carga inicial de usuarios
    fetchData = async () => {

        this.setState({ loading: true, error: null, nameFilter: "" });

        // Obtenemos resultados
        try {
            let response = await api.users.list( );
            // console.log("Resultados: ", response.data);
            if (response.code == 200)
                this.setState({ loading: false, error: null, data: response.data.users });
            else
                this.setState({ loading: false, error: "Algo no salió bien. Por favor, inténtalo de nuevo.", data: [] });

        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

    }


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            this.fetchData();

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    componentWillUnmount() {
        
    }

    handleChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        });

    }

    showSnackbar = ( message ) => {

        this.setState( {showSnackbar: true, snackbarMessage: message} );

    }
        
    handleSnackbarClose = (e) => {

        this.setState( {showSnackbar: false} );

    }

    addUser = (e) => {

        alert("Añadiendo usuario!!");

    }

    eliminarUsuario = async ( e ) => {

        try {

            // Llamámos al método de aprobación de imágenes de la API
            let response = await api.users.remove( e.currentTarget.id );

            // console.log( "Respuesta aprobación: ", response );

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("users_eliminado_ok") );
            else
                this.showSnackbar( lang.getLabel("users_eliminado_no")  );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    changePass = async ( e ) => {

        try {

            // Llamámos al método de aprobación de imágenes de la API
            let response = await api.users.changePass( e.currentTarget.id );

            // console.log( "Respuesta aprobación: ", response );

            if (response.code == 200)
                this.showSnackbar( `${lang.getLabel("users_newpass_ok")}=${response.data.id}: ${response.data.pass}` );
            else
                this.showSnackbar( lang.getLabel("users_newpass_no") );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    changePermission = async ( e ) => {

        console.log( "Admin? ",  );

        try {

            // Llamámos al método de aprobación de imágenes de la API
            let response = await api.users.changePermission( e.currentTarget.id, e.currentTarget.attributes.getNamedItem('admin').value );

            console.log( "Respuesta: ", response );

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("users_permisos_ok") );
            else
                this.showSnackbar( lang.getLabel("users_permisos_no") );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>

                    <div className="users-details-page">

                        {
                        /*
                            <div className="name-search-wrapper">
                                <TextField
                                    id="name-search"
                                    label={lang.getLabel("users_filtrar")}
                                    type="search"
                                    name="nameFilter"
                                    value={this.state.nameFilter}
                                    onChange={this.handleChange}
                                />
                            </div>
                        */
                        }

                        <div className="add-user-wrapper">
                            <NuevoUsuario showSnackbar={this.showSnackbar}/>
                        </div>

                        <div className="own-spacer-md"></div>

                        {console.log(this.state.data)}

                        {// !this.state.loading && this.state.data && this.state.data.filter(user => user.email.indexOf(this.state.nameFilter) > -1 ).map((user, index) => {
                            !this.state.loading && this.state.data && this.state.data.map((user, index) => {
                            return (

                                <Paper className="users-paper-card" elevation={1} variant="outlined" key={index}>

                                    <Avatar className="avatar-img" alt={ user.name.toUpperCase() } src="/static/images/avatar/1.jpg" />

                                    <div className="own-spacer-sm"></div>

                                    <p><span className="users-page-label">{lang.getLabel("users_id")}: </span>{user.id}</p>

                                    <p><span className="users-page-label">{lang.getLabel("users_username")}: </span>{user.name}</p>

                                    <p><span className="users-page-label">{lang.getLabel("users_email")}: </span>{user.email}</p>

                                    <p><span className="users-page-label">{lang.getLabel("users_rol")}: </span>{user.admin == 0 ? "Administrador" : "Invitado"}</p>

                                    <p><span className="users-page-label">{ lang.getLabel("users_verificado") }: </span>{user.verificado == 0 ? lang.getLabel("users_verificado_no") : lang.getLabel("users_verificado_ok") }</p>

                                    <Button id={user.id} className="users-bar-btn" variant="contained" size="small" color="primary" onClick={ this.changePass } >{lang.getLabel("users_nueva_pass")}</Button>
                                    { user.admin == 0 && <Button id={user.id} admin={""+user.admin} className="users-bar-btn" variant="contained" size="small" color="secondary" onClick={ this.changePermission }>{lang.getLabel("users_revocar_admin")}</Button> }
                                    { user.admin != 0 && <Button id={user.id} admin={""+user.admin} className="users-bar-btn" variant="contained" size="small" color="secondary" onClick={ this.changePermission } >{lang.getLabel("users_convertir_admin")}</Button> }
                                    <Button id={user.id} className="users-bar-btn" variant="contained" size="small" color="alert" onClick={ this.eliminarUsuario } >{lang.getLabel("users_eliminar")}</Button>

                                </Paper>

                            );
                        })}

                    </div>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.showSnackbar}
                        /* autoHideDuration={0} */
                        onClose={this.handleSnackbarClose}
                        message={this.state.snackbarMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
    
                </React.Fragment>
            );

        }
        
    }
}

export default Users;
