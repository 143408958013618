// REACT
import React from "react";

// CSS
import "./ResetPass.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";

// Otros componentes
import Loader from '../../components/loader/Loader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


class ResetPass extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            newUserPass: "",
            userPassHelper: "",
            formWarning: "",
        };

    }

    componentDidMount() {
        // Comprobamos si ya existe un token, y en tal caso redirigimos a la home
        if ( storage.token.get() )
            this.props.history.push("/");
    }


    handleChange = (e) => {

        this.setState({
            // Con las siguientes líneas mantenemos todos los valores que había en form, y añadimos o sobreescribimos uno nuevo
            ...this.state,
            [e.target.name]: e.target.value
        });

        if (this.state.newUserPass.length > 0) {
            this.setState({ userPassHelper: "" });
        }

    }


    handleSubmit = (e) => {

        e.preventDefault();

        if ( (this.state.newUserPass.length > 7) ) {

            // Si la respuesta del server es satisfactoria, debemos crear la sesión (generar token) y redirigir a la home
            // Si no lo es, mostraremos mensaje a través de la variable formWarning
            this.setNewPass();
            

        }
        else {

            this.setState({ userPassHelper: "La contraseña debe contener al menos 8 caracteres." });
        
        }

    }

    setNewPass = async ( ) => {
        
        this.setState({ loading: true, error: null });

        try {

            let response = await api.users.userChangePass( storage.user.getName(), storage.user.getTmpPass(), this.state.newUserPass );
        
            // console.log( "Reset Pass response: ", response );

            if ( response.code != 200 ) {
                this.setState({ formWarning: response.data.error_message  });
            }
            else {
                // Intentamos generar token
                await this.getToken();
            }

            this.setState({ loading: false, error: null, data: response });

        }
        catch( err ) {
            this.setState({ loading: false, error: err });
        }

        // Eliminamos, por limpieza, pass temporal del localstorage
        storage.user.deleteTmpPass( );

    }

    getToken = async ( ) => {

        this.setState({ loading: true, error: null });

        try {

            let response = await api.users.getToken( {name: storage.user.getName(), pass: this.state.newUserPass} );

            if ( response.code != 200 ) {
                this.setState({ formWarning: response.data.error_message  });
            }
            else {
                this.setState({ formWarning: "" });

                // Aplicar token en localstorage a través del servicio de storage creado
                storage.token.set( response.data.token );
                storage.user.setName( response.data.user.name );
                storage.user.setEmail( response.data.user.email );
                storage.user.setAdmin( response.data.user.admin );
                storage.user.setUserID( response.data.user.id );

                // Redirigir a Home
                this.props.history.push("/");

            }

            this.setState({ loading: false, error: null, data: response });
        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

    }


    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="login-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        return (
            <React.Fragment>

                <div className="login_page">

                    <div className="login_page_inner">

                        <div className="own-spacer-md"></div>

                        <div id="login-form-box">
                            <div id="login-form-box-inner">
                            
                                <h2>Nueva contraseña</h2>

                                <p>Para terminar de configurar su usuario debe escoger una nueva contraseña.</p>

                                <div className="own-spacer-xs"></div>

                                <form className="login-form" noValidate autoComplete="off" onSubmit={ this.handleSubmit }>

                                    <TextField
                                        id="outlined-basic"
                                        name="newUserPass"
                                        label="Nueva contraseña"
                                        variant="outlined"
                                        error={this.state.userPassHelper.length > 0}
                                        helperText={this.state.userPassHelper}
                                        value={this.state.newUserPass}
                                        onChange={this.handleChange}
                                    />

                                    <div className="own-spacer-xs"></div>

                                    <Button type="submit" variant="contained" size="large" color="primary" >Enviar</Button>

                                    { (this.state.formWarning.length > 0) && <div id="form-warning">{this.state.formWarning}</div> }

                                </form>

                            </div>    
                        </div>

                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default ResetPass;
