// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./PendingPhotos.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import PendingPhotoCard from "../../components/pending-photo-card/PendingPhotoCard";
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';


class PendingPhotos extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            error: null,
            // data: undefined,
            data: undefined,
            // pendingPhotosCount: 0,
            showRejected: 1,
            showSnackbar: false,
            snackbarMessage: "",
        };

    }

    // Creamos una señal de control para poder cancelar el Fetch
    controller = new AbortController();
    signal = this.controller.signal;

    // Método para la carga inicial de fotos
    fetchData = async ( offset ) => {

        this.setState({ loading: true, error: null });

        // Obtenemos resultados
        try {
            let response = await api.pending_photos.list( this.signal );
            // console.log("Resultados: ", response.data);
            if (response.code == 200)
                this.setState({ loading: false, error: null, data: response.data.photos });
            else if (response.code == 501) {
                storage.token.remove();
                this.props.history.push("/login");
            } // Caso de token no válido
            else {
                console.log("Response: ", response);
                this.setState({ loading: false, error: "Algo no salió bien. Por favor, inténtalo de nuevo.", data: [] });
            }

        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

        // Obtenemos num resultados (fotos pendientes)
        try {

            let response = await api.pending_photos.count();
            // console.log("Num resultados: ", response.data.photoCount);
            if (response.code == 200) {
                this.props.setBadgeNumber( response.data.photoCount );
            }

        }
        catch (e) {
            // this.setState({ loading: false, error: e });
        }

    }


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token y no hay contenido ya cargado, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            this.fetchData();

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    /*
    componentDidUpdate(prevProps, prevState) {
        // Imprimimos los valores previos al cambio de props y state:
        console.log({
            prevProps: prevProps,
            prevState: prevState
        })
        // y también imprimimos los valores actuales:
        console.log({
            props: this.props,
            state: this.state
        })
    }
    */

    componentWillUnmount() {
        // Cancelamos el fetch (el de listado de fotografías)
        this.controller.abort();
    }

    showSnackbar = ( message ) => {

        this.setState( {showSnackbar: true, snackbarMessage: message} );

    }
        
    handleSnackbarClose = (e) => {

        this.setState( {showSnackbar: false} );

    }

    rotarImagen = async ( e ) => {

        try {

            // Llamamos al método de eliminación de imágenes de la API
            let response = await api.photos.prerotate( e.currentTarget.id, 90 );
            // console.log("Respuesta (rotación de foto): ", response);

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("aprob_rotado_ok") );
            else
                this.showSnackbar( lang.getLabel("aprob_rotado_no") );

            // Volvemos a solicitar la información del pedido
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    aprobarImagen = async ( e ) => {

        try {

            // Llamámos al método de aprobación de imágenes de la API
            let response = await api.photos.approvePending( e.currentTarget.id );

            // console.log( "Respuesta aprobación: ", response );

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("aprob_aprobado_ok") );
            else
                this.showSnackbar( lang.getLabel("aprob_aprobado_no") );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    rechazarImagen = async ( e ) => {

        try {

            // Llamámos al método de rechazo de imágenes de la API
            let response = await api.photos.updatePendingState( e.currentTarget.id );

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("aprob_rechazado_ok") );
            else
                this.showSnackbar( lang.getLabel("aprob_rechazado_no") );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    eliminarImagen = async ( e ) => {

        try {

            // Llamámos al método de eliminación de imágenes de la API
            let response = await api.photos.deletePending( e.currentTarget.id );

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("aprob_eliminado_ok") );
            else
                this.showSnackbar( lang.getLabel("aprob_eliminado_no") );

            // Volvemos a solicitar la información de fotos pendientes
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }


    setShowRejected = () => {
        let b = !this.state.showRejected;
        this.setState( {showRejected : b} );
    }


    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            console.log( "Error: ", this.state.error);
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>
    
                    <div className="pending_photos_page">
    
                        <div className="pending_photos_page_inner">
    
                            { this.state.loading && <React.Fragment> <div className="home-loader-2-wrapper"><Loader /></div> </React.Fragment> }

                            { !this.state.loading && <React.Fragment>
                                <div className="hidden-card-filter">
                                
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={ this.state.showRejected ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                        onClick={this.setShowRejected}
                                    >
                                        {lang.getLabel("apro_mostrar_rechazadas")}
                                    </Button>
                                
                                </div>
                            </React.Fragment> }

                            { !this.state.loading && this.state.data && (this.state.data.length == 0) &&
                                <React.Fragment> <div style={{padding: "35px"}}>No se han encontrado resultados.</div> </React.Fragment>
                            }
    
                            {!this.state.loading && this.state.data && this.state.data.map((photo, index) => {
                                return (
                                        <PendingPhotoCard
                                            photo={photo}
                                            key={index}
                                            aprobarImagen={this.aprobarImagen}
                                            rechazarImagen={this.rechazarImagen}
                                            rotarImagen={this.rotarImagen}
                                            eliminarImagen={this.eliminarImagen}
                                            showRejected={this.state.showRejected}
                                        >

                                        </PendingPhotoCard>  
                                );
                            })}
    
                        </div>
    
                    </div>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.showSnackbar}
                        autoHideDuration={4000}
                        onClose={this.handleSnackbarClose}
                        message={this.state.snackbarMessage}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
    
                </React.Fragment>
            );

        }
        
    }
}

export default PendingPhotos;
