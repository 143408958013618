// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./Home.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import OrderCard from "../../components/order-card/OrderCard";
import OrderCardMini from "../../components/order-card-mini/OrderCardMini";
import PanelFiltros from "../../components/panel-filtros/PanelFiltros";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


class Home extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            error: null,
            // data: undefined,
            data: undefined,
            currentFilters: {},
            currentPage: 1,
            totalResuls: 0,
            showMobileFilterPanel: false,
        };

    }

    selectedDate = new Date();
    // Creamos una señal de control para poder cancelar el Fetch
    controller = new AbortController();
    signal = this.controller.signal;

    // Método para la carga inicial de fotos
    fetchData = async ( offset ) => {

        this.setState({ loading: true, error: null });

        // Obtenemos resultados
        try {
            let response = await api.photos.list( this.signal, null, offset );
            // console.log("Resultados: ", response.data);
            if (response.code == 200)
                this.setState({ loading: false, error: null, data: response.data.photos });
            else if (response.code == 501) {
                storage.token.remove();
                this.props.history.push("/login");
            } // Caso de token no válido
                
            else
                this.setState({ loading: false, error: "Algo no salió bien. Por favor, inténtalo de nuevo.", data: [] });

        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

        // Obtenemos num resultados (para paginación)
        try {
            let response = await api.photos.count( );
            // console.log("Num resultados: ", response.data.photoCount);
            if (response.code == 200)
                this.setState({ loading: false, error: null, totalResults: response.data.photoCount });
            else
                this.setState({ loading: false, error: null, totalResults: 0, data: [] });
        }
        catch (e) {
            // console.log("Fallako: ", "Turbiooo");
            this.setState({ loading: false, error: e });
        }

    }

    // Método para la carga de fotos con filtros (consumible por PanelFiltros.js)
    applyFilters = async ( filters = {}, resetOffset = false, offset = 0 ) => {

        // Si se ha pulsado botón de buscar reseteamos la paginación
        if (resetOffset) {
            this.setState( {currentPage: 1} );
        }

        if( Object.keys(filters).length > 0 )
            this.setState( {currentFilters: filters} );

        // console.log( "Given Filters: ", filters );
        // console.log( "Current Filters to apply: ", this.state.currentFilters );
        // console.log( "Offset to apply: ", offset );

        this.setState({ loading: true, error: null, showMobileFilterPanel: false });
        // this.setState({ loading: true, error: null, data: undefined });

        try {

            let response = null;

            if ( resetOffset )
                response = await api.photos.list( this.signal, filters, 0);
            else
                response = await api.photos.list( this.signal, this.state.currentFilters, offset );

            // console.log("Respuesta (fotos): ", response);

            if (response.code == 200)
                this.setState({ loading: false, error: null, data: response.data.photos});
            else
                this.setState({ loading: false, error: null, data: []});
        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

        // Obtenemos num resultados (para paginación)
        try {
            let response = await api.photos.count( filters );
            // console.log("Num resultados: ", response.data.photoCount);
            this.setState({ loading: false, error: null, totalResults: response.data.photoCount });
        }
        catch (e) {
            // this.setState({ loading: false, error: e });
        }

    }

    // Método para la cuenta de fotos pendientes de revisión
    countPendingPhotos = async ( ) => {
        // Obtenemos num resultados (fotos pendientes)
        try {

            let response = await api.pending_photos.count();
            // console.log("Num resultados: ", response.data.photoCount);
            if (response.code == 200) {
                this.props.setBadgeNumber( response.data.photoCount );
            }

        }
        catch (e) {
            // this.setState({ loading: false, error: e });
        }
    };


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token y no hay contenido ya cargado, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            this.fetchData();

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Miramos número de fotos pendientes para actualizar el badge
        this.countPendingPhotos();

        // Mostramos icono de cambio de vista
        this.props.setShowViewSwitch(true);

    }

    /*
    componentDidUpdate(prevProps, prevState) {
        // Imprimimos los valores previos al cambio de props y state:
        console.log({
            prevProps: prevProps,
            prevState: prevState
        })
        // y también imprimimos los valores actuales:
        console.log({
            props: this.props,
            state: this.state
        })
    }
    */

    componentWillUnmount() {
        // Cancelamos el fetch (el de listado de fotografías)
        this.controller.abort();
    }


    /* Métodos relacionados con el filtro */
    handleDateChange( date ) {

        this.selectedDate = date;

    }

    handlePreviousPage = () => {

        let newPage = this.state.currentPage;
        
        if ( newPage > 1 )
            newPage = newPage - 1;

        this.setState({ currentPage: newPage });

        let offset = 0;
        if ( newPage > 1 )
            offset = (newPage-1)*25;

        // console.log( "Current Page: ", newPage );
        // console.log( "Offset: ", offset );

        if( Object.keys(this.state.currentFilters).length == 0 )
            this.fetchData( offset );
        else
            this.applyFilters( this.state.currentFilters, false, offset );

    }

    handleNextPage = () => {

        let newPage = this.state.currentPage;

        newPage = newPage + 1;

        this.setState({ currentPage: newPage });

        let offset = 0;
        if ( newPage > 1 )
            offset = (newPage-1)*25;

        console.log( "Current Page: ", newPage );
        console.log( "Offset: ", offset );

        if( Object.keys(this.state.currentFilters).length  == 0 )
            this.fetchData( offset );
        else
            this.applyFilters( this.state.currentFilters, false, offset );

    }

    openFilterPanel = () => {

        this.setState( {showMobileFilterPanel: !this.state.showMobileFilterPanel} );
        
    }

    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            console.log( "Error: ", this.state.error);
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>
    
                    <div className="home_page">
    
                        <div id="filter-sidebar" className={ this.state.showMobileFilterPanel ? "expanded-panel" : "" }>
    
                            <div id="filter-sidebar-inner">
                                
                                <div className="own-spacer-sm"></div>
    
                                <PanelFiltros applyFilters={this.applyFilters} resetPagination={this.resetPagination} ></PanelFiltros>

                            </div>
    
                        </div>
    
                        <div className="home_page_inner">
    
                            { this.state.loading && <React.Fragment> <div className="home-loader-2-wrapper"><Loader /></div> </React.Fragment> }

                            <div className="pagination-system">

                                {!this.state.loading && ( this.state.totalResults > 0 ) && ( this.state.totalResults > this.state.data.length ) &&
                                    /* <p>{this.state.currentPage*50-49}-{this.state.currentPage*50 - 50 + this.state.data.length} {lang.getLabel("paginacion_de")} {this.state.totalResults} {lang.getLabel("paginacion_resultados")}.</p> */
                                    <p>{this.state.currentPage*25-24}-{this.state.currentPage*25 - 25 + this.state.data.length} {lang.getLabel("paginacion_de")} {this.state.totalResults} {lang.getLabel("paginacion_resultados")}.</p>
                                }

                                {!this.state.loading && ( this.state.totalResults > this.state.data.length ) && this.state.currentPage > 1 &&
                                    <div className="pagination-system-link" onClick={this.handlePreviousPage}><p>{lang.getLabel("paginacion_anterior")}</p></div>
                                }


                                {!this.state.loading && ( this.state.totalResults > this.state.data.length ) && ( (this.state.currentPage*25 - 25 + this.state.data.length ) < this.state.totalResults ) &&
                                    <div className="pagination-system-link" onClick={this.handleNextPage}><p>{lang.getLabel("paginacion_siguiente")}</p></div>
                                }

                                {!this.state.loading && ( this.state.totalResults > 0 ) && ( this.state.totalResults == this.state.data.length ) &&
                                    <p>{lang.getLabel("paginacion_mostrando")} {this.state.data.length} {lang.getLabel("paginacion_resultados")}.</p>
                                }

                            </div>

                            { !this.state.loading && this.state.data && (this.state.data.length == 0) &&
                                <React.Fragment> <div style={{padding: "35px"}}>No se han encontrado resultados.</div> </React.Fragment>
                            }
    
                            {!this.state.loading && this.props.view && this.state.data && this.state.data.map((photo, index) => {
                                return (
                                    <OrderCard photo={photo} key={index}></OrderCard>
                                );
                            })}


                            {!this.state.loading && !this.props.view && this.state.data && this.state.data.map((photo, index) => {
                                return (
                                    <OrderCardMini photo={photo} key={index}></OrderCardMini>
                                );
                            })}


                            <div className="pagination-system">

                                {!this.state.loading && ( this.state.totalResults > 0 ) && ( this.state.totalResults > this.state.data.length ) &&
                                    /* <p>{this.state.currentPage*50-49}-{this.state.currentPage*50 - 50 + this.state.data.length} {lang.getLabel("paginacion_de")} {this.state.totalResults} {lang.getLabel("paginacion_resultados")}.</p> */
                                    <p>{this.state.currentPage*25-24}-{this.state.currentPage*25 - 25 + this.state.data.length} {lang.getLabel("paginacion_de")} {this.state.totalResults} {lang.getLabel("paginacion_resultados")}.</p>
                                }

                                {!this.state.loading && ( this.state.totalResults > this.state.data.length ) && this.state.currentPage > 1 &&
                                    <div className="pagination-system-link" onClick={this.handlePreviousPage}><p>{lang.getLabel("paginacion_anterior")}</p></div>
                                }


                                {!this.state.loading && ( this.state.totalResults > this.state.data.length ) && ( (this.state.currentPage*25 - 25 + this.state.data.length ) < this.state.totalResults ) &&
                                    <div className="pagination-system-link" onClick={this.handleNextPage}><p>{lang.getLabel("paginacion_siguiente")}</p></div>
                                }

                                {!this.state.loading && ( this.state.totalResults > 0 ) && ( this.state.totalResults == this.state.data.length ) &&
                                    <p>{lang.getLabel("paginacion_mostrando")} {this.state.data.length} {lang.getLabel("paginacion_resultados")}.</p>
                                }

                            </div>

                            <IconButton onClick={this.openFilterPanel} className="filter-floating-btn" color="primary" aria-label="Realizar búsqueda">
                                {this.state.showMobileFilterPanel ? <ClearIcon fontSize="large" /> : <SearchIcon fontSize="large" /> }
                            </IconButton>

    
                        </div>
    
                    </div>
    
                </React.Fragment>
            );

        }
        
    }
}

export default Home;
