// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./OrderDetails.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';

import { render } from 'react-dom';
import ImageViewer from 'react-simple-image-viewer';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import Button from '@material-ui/core/Button';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import StarIcon from '@material-ui/icons/Star';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';


class OrderDetails extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            allData: undefined,
            currentImage: 0,
            viewerOpened: false,
            images: [

            ],
            imageToDelete: -1,
            imageHash: Date.now(),
            dialogOpened: false,
            showSnackbar: false,
            snackbarMessage: "",

        };

    }

    // Método para la carga inciial de fotos
    fetchData = async () => {

        this.setState({ loading: true, error: null });

        try {
            let response = await api.photos.getBySerialNum( this.props.match.params.orderId );
            
            // console.log("Respuesta (fotos): ", response);

            let photos = [];
            response.data.photos.forEach( photo => { photos.push( api.info.getBaseUrl() + "/backend/photos/storage/" + photo.path ); });

            this.setState({ loading: false, error: null, data: response.data.photos[0], allData: response.data.photos, images: photos });
        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

    }


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            this.fetchData();

            // TODO: Comprobación de token válido (quizá en la rutina de fetchData)

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Miramos número de fotos pendientes para actualizar el badge
        this.countPendingPhotos();

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    componentWillUnmount() {

    }

    openImageViewer = ( index ) => {

        this.setState({ currentImage: index, viewerOpened: true });

    }

    // Método para la cuenta de fotos pendientes de revisión
    countPendingPhotos = async ( ) => {
        // Obtenemos num resultados (fotos pendientes)
        try {

            let response = await api.pending_photos.count();
            // console.log("Count pending: ", response.data.photoCount);
            if (response.code == 200) {
                this.props.setBadgeNumber( response.data.photoCount );
            }

        }
        catch (e) {
            // this.setState({ loading: false, error: e });
        }
    };

    closeImageViewer = () => {

        this.setState({ currentImage: 0, viewerOpened: false });

    }

    handleDeleteBtn = (e) => {

        this.setState( { imageToDelete: e.currentTarget.id, dialogOpened:true } );

    }

    handleRotateBtn = async (e) => {

        // LLamamos directamente a la API para rotar la imagen
        try {

            // Llamámos al método de eliminación de imágenes de la API
            let response = await api.photos.rotate( e.currentTarget.id, 90 );
            // console.log("Respuesta (rotación de foto): ", response);

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("respuesta_rotacion_ok") );
            else
                this.showSnackbar( lang.getLabel("respuesta_rotacion_no") );

            // Volvemos a solicitar la información del pedido
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    handleFeaturedBtn = async (e) => {

        try {

            // Llamámos al método de eliminación de imágenes de la API
            let response = await api.photos.updateMain( e.currentTarget.id );
            // console.log("Respuesta (asignación de imagen destacada): ", response);

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("respuesta_destacada_ok") );
            else
                this.showSnackbar( lang.getLabel("respuesta_destacada_no") );

            // Volvemos a solicitar la información del pedido
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

    }

    handleDialogClose = (e) => {

        this.setState({dialogOpened:false});

    }

    handleAddPhotos = (e) => {

        // Ir a página de añadir fotos especificando ID (num serie)

        let path = `/upload?id=${this.props.match.params.orderId}`;
        this.props.history.push( path );

    }

    handleDialogConfirm = async (e) => {

        try {

            // Llamámos al método de eliminación de imágenes de la API
            let response = await api.photos.delete( this.state.imageToDelete );
            // console.log("Respuesta (borrado de foto): ", response);

            if (response.code == 200)
                this.showSnackbar( lang.getLabel("respuesta_eliminada_ok") );
            else
                this.showSnackbar( lang.getLabel("respuesta_eliminada_no") );

            // Volvemos a solicitar la información del pedido
            await this.fetchData();

        }
        catch (e) {
            this.setState({ error: e });
        }

        this.setState({dialogOpened:false});

    }

    showSnackbar = ( message ) => {

        this.setState( {showSnackbar: true, snackbarMessage: message} );

    }

    handleSnackbarClose = (e) => {

        this.setState( {showSnackbar: false} );

    }


    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        else if ( !this.state.loading && this.state.data ) {

            return (
                <React.Fragment>

                    <div className="order-details-page">

                        <Dialog
                            open={this.state.dialogOpened}
                            onClose={this.handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Confirmación de borrado"}</DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ¿Seguro que quieres eliminar esta imagen?
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions className="dialog-actions-zone">
                            <Button onClick={this.handleDialogClose} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleDialogConfirm} color="primary" autoFocus>
                                Eliminar
                            </Button>
                            </DialogActions>
                        </Dialog>

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.showSnackbar}
                            autoHideDuration={4000}
                            onClose={this.handleSnackbarClose}
                            message={this.state.snackbarMessage}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        />

                        <div className="order-details-photos-section">

                            { (this.state.images.length == 1) && (this.state.images[0].indexOf("/null") > -1) && <img className="order-details-default-img" alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/default.png" } />}

                            {

                                /*this.state.
                                .map((photo, index) => {*/
                                !(this.state.images[0].indexOf("/null") > -1) && this.state.allData.map((photo, index) => {
                                    return (
                                        <div className="details-image-wrapper" key={ index }>
                                            <img
                                                /*src={ photo }*/
                                                src={api.info.getBaseUrl() + "/backend/photos/storage/" + photo.path + '?' + this.state.imageHash }
                                                onClick={ () => this.openImageViewer(index) }
                                                width="300"
                                                key={ photo.id }
                                                style={{ margin: '2px' }}
                                                alt=""
                                            />
                                            {(storage.user.getAdmin() == 0) && <div className="photo-action-btns">
                                                <IconButton
                                                    aria-label="delete"
                                                    style={{ color: "white" }}
                                                    id={ photo.id }
                                                    onClick={this.handleDeleteBtn}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="delete"
                                                    style={{ color: "white" }}
                                                    id={ photo.id }
                                                    onClick={this.handleRotateBtn}
                                                >
                                                    <RotateRightIcon />
                                                </IconButton>
                                                { (photo.id != photo.main_photo) &&
                                                <IconButton
                                                    aria-label="update-main"
                                                    style={{ color: "white" }}
                                                    id={ photo.id }
                                                    onClick={this.handleFeaturedBtn}
                                                >
                                                    <StarIcon />
                                                </IconButton>
                                                } 
                                            </div>
                                            }
                                            {(photo.id == photo.main_photo) &&
                                                <div className="photo-featured-banner">
                                                    {lang.getLabel("destacada")}
                                                </div>
                                            }
                                        </div>
                                    );
                                })

                            }

                            <div className="own-spacer-xs"></div>

                            {
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<AddAPhotoIcon />}
                                    onClick={this.handleAddPhotos}
                                >
                                    {lang.getLabel("anadir_fotos")}
                                </Button>
                            }

                            {this.state.viewerOpened && (
                                <ImageViewer
                                    src={ this.state.images}
                                    currentIndex={ this.state.currentImage }
                                    onClose={ this.closeImageViewer }
                                />
                            )}

                        </div>

                        
                        <div className="order-details-info-section">

                            <p><span className="order-details-page-label">{lang.getLabel("num_serie")}: </span>{this.state.data.serial_num}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("referencia")}: </span>{this.state.data.ref_pedido}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("cliente")}: </span>{this.state.data.client_id}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("localidad")}: </span>{this.state.data.order_location}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("provincia")}: </span>{this.state.data.provincia}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("direccion")}: </span>{this.state.data.direccion}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("fecha_pedido")}: </span>{this.state.data.order_date}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("descripcion")}: </span>{this.state.data.order_desc}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_accesorio_estantes")}: </span>{this.state.data.accesorios_estantes}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_accesorio_controles")}: </span>{this.state.data.accesorios_controles}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_accesorio_laterales")}: </span>{this.state.data.accesorios_laterales}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_accesorio_iluminacion")}: </span>{this.state.data.accesorios_iluminacion}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_color_decoraciones")}: </span>{this.state.data.color_decoraciones}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_color_interior")}: </span>{this.state.data.color_interior}</p>
                            <p><span className="order-details-page-label">{lang.getLabel("f_refrigerante")}: </span>{this.state.data.refrigerante}</p>

                        </div>

                    </div>
    
                </React.Fragment>
            );

        }

        else {

            return (
                <React.Fragment>
                    <p style={ {marginTop: "120px", paddingLeft: "50px"} }>Este número de serie no existe.</p>
                </React.Fragment>
            );

        }
        
    }
}

export default OrderDetails;
