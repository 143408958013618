let userLang = "es";

// Intentamos leer la variable de localStorage (si fue establecida) y si no detectamos idioma del usuario
if ( localStorage && localStorage.getItem("exkal-lang") )
    userLang = localStorage.getItem("exkal-lang");
else {
    userLang = navigator.language || navigator.userLanguage; 
    if (userLang.indexOf("es") > -1 ) {
        userLang = 0;
    }
    else if (userLang.indexOf("fr") > -1) {
        userLang = 2;
    }
    else {
        // Inglés como lenguaje por defecto si no se detecta español o francés
        userLang = 1;
    }
    localStorage.setItem("exkal-lang", userLang);
}


const translations = {

    /* Traducciones menú superior y menú desplegable de usuario */
    cambiar_vista : ["Cambiar vista", "Change view", "Changer la vue"],
    subir_foto : ["Subir foto(s)", "Upload photo(s)", "Télécharger photo(s)"],
    pendientes_aprobacion : ["Fotos pendientes de aprobación", "Photos pending approval", "Photos en attente d'approbation"],
    manual : ["Ver manual", "User guide", "Voir guide"],
    perfil : ["Mi perfil", "My profile", "Mon profil"],
    gestionar_usuarios : ["Gestionar usuarios", "Manage users", "Gérer les utilisateurs"],
    log_eventos : ["Log de eventos", "Events Log", "Log des événements"],
    cerrar_sesion : ["Cerrar sesión", "Log out", "Fermer la session"],

    /* Traducciones ficha de cliente */
    cliente : ["Cliente", "Customer", "Client"],
    localizacion : ["Localización", "Location", "Localisation"],
    descripcion : ["Descripción", "Description", "La description"],
    num_serie : ["Nº Serie", "Serial Number", "Nº de série"],
    referencia : ["Referencia de pedido", "Order reference", "Référence de l'achat"],
    ver_mas : ["Ver más", "See more", "Voir plus"],

    /* Traducciones extra ficha de cliente */
    localidad : ["Localidad", "Locality", "Localité"],
    provincia : ["Provincia", "Province", "Province"],
    direccion : ["Dirección", "Address", "Adresse"],
    fecha_pedido : ["Fecha de pedido", "Order date", "Date de la commande"],
    modelo : ["Modelo", "Model", "Modèle"],
    destacada : ["Destacada", "Featured", "En vedette"],
    anadir_fotos : ["Añadir fotos", "Add photos", "Ajouter des photos"],    

    /* Traducciones panel de filtros */
    f_filtros : ["Filtros", "Filters", "Filtres"],
    f_reset : ["Resetear filtros", "Reset filters", "Réinitialiser les filtres"],
    f_num_serie_ref : ["Nº Serie o Referencia", "Serial Number or Reference", "N° de série ou référence"],
    f_cliente : ["Cliente", "Customer", "Client"],
    f_localizacion : ["Localización", "Location", "Localisation"],
    f_modelo_base : ["Modelo base", "Base model", "Modèle de base"],
    f_refrigerante : ["Refrigerante", "Refrigerant", "Réfrigérant"],
    f_fecha : ["Filtrar por fecha", "Filter by date", "Filtrer par date"],
    f_fecha_inicio : ["Fecha inicio", "Start date", "Date de début"],
    f_fecha_final : ["Fecha final", "End date", "Date de fin"],
    f_color : ["Filtrar por color", "Filter by color", "Filtrer par couleur"],
    f_color_decoraciones : ["Color decoraciones", "Decoration color", "Couleur décorations"],
    f_color_interior : ["Color interior", "Interior color", "Couleur intérieure"],
    f_accesorio : ["Filtrar por accesorio", "filter by accessory", "Filtrer par accessoire"],
    f_accesorio_estantes : ["Accesorios estantes", "Shelf accessories", "Accessoires étagères"],
    f_accesorio_controles : ["Accesorios controles", "Accessories controls", "Accessoires contrôles"],
    f_accesorio_laterales : ["Accesorios laterales", "Side accessories", "Accessoires latéraux"],
    f_accesorio_iluminacion : ["Accesorios iluminación", "Lighting accessories", "Accessoires luminosité"],
    f_solo_foto : ["Sólo resultados con foto", "Only results with photo", "Uniquement résultats avec photos"],
    f_buscar : ["Buscar", "Search", "Chercher"],

    /* Traducciones adicionales varias */
    paginacion_mostrando : ["Mostrando", "Showing", "Montrant"],
    paginacion_anterior : ["Anterior", "Previous", "Antérieure"],
    paginacion_siguiente : ["Siguiente", "Next", "Suivant"],
    paginacion_de : ["de", "of", "sur"],
    paginacion_resultados : ["resultados", "results", "résultats"],
    subida_titulo : ["Subida de fotografías", "Photo uploads", "Téléchargement de la photo"],
    subida_escoger : ["Escoger fotografías", "Choose pictures ", "Choisir photos"],
    subida_publicar : ["Publicar", "Publish ", "Publier"],

    /* Traducciones de feedback al usuario */
    respuesta_rotacion_ok : ["La imagen se ha rotado correctamente.", "The image has been rotated correctly.", "L'image a été pivotée correctement."],
    respuesta_rotacion_no : ["No se ha podido rotar la imagen.", "The image could not be rotated.", "L'image n'a pas pu être pivotée."],
    respuesta_destacada_ok : ["La imagen se ha asignado como destacada correctamente.", "The image has been assigned as featured successfully.", "L'image a été attribuée comme vedette avec succès."],
    respuesta_destacada_no : ["No se ha podido asignar la imagen como destacada.", "The image could not be assigned as featured.", "L'image n'a pas pu être attribuée comme vedette."],
    respuesta_eliminada_ok : ["La imagen se ha eliminado correctamente.", "The image has been deleted successfully.", "L'image a été supprimée avec succès."],
    respuesta_eliminada_no : ["No se ha podido eliminar la imagen.", "Unable to delete image.", "Impossible de supprimer l'image."],
    respuesta_subida_ok : ["La(s) foto(s) se ha(n) subido correctamente.", "The photos have been uploaded successfully.", "Les photos ont été téléchargées avec succès."],
    respuesta_subida_espera : ["La(s) foto(s) se ha(n) subido correctamente y queda(n) a la espera de aprobación por un administrador.", "The photos have been successfully uploaded and are awaiting approval by an administrator.", "Les photos ont été téléchargées avec succès et sont en attente d'approbation par un administrateur."],
    respuesta_subida_no : ["Hubo algún problema subiendo la(s) foto(s).", "There was a problem uploading the photos.", "Un problème est survenu lors du téléchargement des photos."],
    respuesta_minimo_una_img : ["Se debe añadir al menos una imagen.", "At least one image must be added.", "Au moins une image doit être ajoutée."],
    respuesta_tamano_maximo : ["Las imágenes deben pesar menos de 8 MB.", "Images must be less than 8MB.", "Les images doivent faire moins de 8 MB."],
    respuesta_especificar_num_serie : ["Se debe especificar un número de serie.", "A serial number must be specified.", "Un numéro de série doit être spécifié."],
    respuesta_ver_resultado : ["Ver resultado", "See result", "Voir le résultat"],

    /* Traducciones datos de perfil */
    perfil_titulo: ["Datos de usuario", "User data", "Données d'utilisateur"],
    perfil_nombre: ["Nombre de usuario", "Username", "Nom d'utilisateur"],
    perfil_email: ["Email", "Email", "Email"],
    perfil_rol: ["Rol", "Role", "Rôle"],
    perfil_rol_admin: ["Administrador", "Admin", "Admin"],
    perfil_rol_invitado: ["Invitado", "Guest", "Invité"],
    perfil_idioma: ["Idioma", "Language", "Langue"],
    perfil_idioma_tip: ["El idioma afectará a la interfaz de usuario de forma automática.", "The language will affect the user interface automatically.", "La langue affectera automatiquement l'interface utilisateur."],
    perfil_spanish: ["Español", "Spanish", "Espagnole"],
    perfil_english: ["Inglés", "English", "L'anglais"],
    perfil_french: ["Francés", "French", "Français"],

    /* Traducciones página logs */
    log_accion: ["Acción", "Action", "Action"],
    log_fecha_hora: ["Fecha/Hora", "Date/Hour", "Date/Heure"],
    log_usuario: ["Usuario", "User", "Utilisatrice"],
    log_foto_afectada: ["Foto afectada", "Affected Photo", "Photo affectée"],
    log_error_fecha: ["La fecha de final debe ser igual o superior a la fecha de inicio.", "The end date must be equal to or greater than the start date.", "La date de fin doit être égale ou supérieure à la date de début."],
    log_default_100: ["Por fecto se muestran las 100 últimas acciones.", "By default the last 100 actions are shown.", "Par défaut, les 100 dernières actions sont affichées."],
    log_filtros_msg: ["Puedes usar el filtro de fechas para afinar la búsqueda.", "You can use the date filter to refine your search.", "Vous pouvez utiliser le filtre de date pour affiner votre recherche."],
    log_fecha_inicio: ["Fecha de inicio", "Start date", "Date de début"],
    log_fecha_final: ["Fecha de final", "End date", "Date de fin"],
    log_fecha_btn: ["Filtrar", "Filter", "Filtre"],
    log_msg_noresults: ["No se han encontrado resultados.", "No results found.", "Aucun résultat trouvé."],
    log_msg_descarga: ["También puedes descargar el informe completo (CSV):", "You can also download the full report (CSV):", "Vous pouvez également télécharger le rapport complet (CSV) :"],

    /* Traducciones página aprobación */
    apro_sin_resultados: ["No se han encontrado resultados", "No results were found", "Aucun résultat trouvé"],
    apro_mostrar_rechazadas: ["Mostrar rechazadas", "Show rejected photos", "Spectacle rejeté"],
    aprob_num_serie: ["Nº Serie", "Serial Nº", "---"],
    aprob_usuario_propietario: ["Usuario propietario", "Owner user", "Nº de série."],
    aprob_rotar: ["Rotar", "Rotate", "Tourner"],
    aprob_aprobar: ["Aprobar", "Approve", "Passe"],
    aprob_rechazar: ["Rechazar", "Reject", "Rejeter"],
    aprob_eliminar: ["Eliminar", "Delete", "Retirer"],
    aprob_rotado_ok: ["La imagen se ha rotado correctamente.", "The image has been rotated correctly.", "L'image a été pivotée correctement."],
    aprob_rotado_no: ["No se ha podido rotar la imagen.", "Failed to rotate image.", "Impossible de faire pivoter l'image."],
    aprob_aprobado_ok: ["La imagen se ha aprobado.", "The image has been approved.", "L'image a été approuvée."],
    aprob_aprobado_no: ["No se ha podido aprobar la imagen.", "The image could not be approved.", "L'image n'a pas pu être approuvée."],
    aprob_rechazado_ok: ["La imagen se ha rechazado.", "The image has been rejected.", "L'image a été rejetée."],
    aprob_rechazado_no: ["No se ha podido rechazar la imagen.", "The image could not be rejected.", "L'image n'a pas pu être rejetée."],
    aprob_eliminado_ok: ["La imagen se ha eliminado correctamente.", "The image has been deleted successfully.", "L'image a été supprimée avec succès."],
    aprob_eliminado_no: ["No se ha podido eliminar la imagen.", "Unable to delete image.", "Impossible de supprimer l'image."],

    /* Traducciones página gestión usuarios */
    users_id: ["ID", "ID", "ID"],
    users_username: ["Nombre de usuario", "Username", "Nom d'utilisateur"],
    users_email: ["Email", "Email", "Email"],
    users_rol: ["Rol", "Role", "Rôle"],
    users_verificado: ["Verificado", "Verified", "Vérifié"],
    users_verificado_ok: ["Sí", "Yes", "Oui"],
    users_verificado_no: ["No", "No", "Non"],
    users_nueva_pass: ["Nueva contraseña", "New password", "Nouveau mot de passe"],
    users_convertir_admin: ["Convertir en admin", "Convert to admin", "Devenir administrateur"],
    users_revocar_admin: ["Revocar admin", "Revoke admin", "Révoquer l'administrateur"],
    users_eliminar: ["Eliminar", "Delete", "Retirer"],
    users_filtrar: ["Filtrar por nombre o email", "Filter by name or email", "Filtrer par nom ou email"],
    users_nuevo: ["Añadir nuevo usuario (email)", "Add new user (email)", "Ajouter un nouvel utilisateur (e-mail)"],
    users_eliminado_ok: ["El usuario se ha eliminado correctamente.", "The user has been deleted successfully.", "L'utilisateur a été supprimé avec succès."],
    users_eliminado_no: ["No se ha podido eliminar el usuario.", "Failed to delete user.", "Échec de la suppression de l'utilisateur."],
    users_permisos_ok: ["Permisos actualizados.", "Permissions updated.", "Autorisations mises à jour."],
    users_permisos_no: ["No se ha podido actualizar los permisos.", "Failed to update permissions.", "Échec de la mise à jour des autorisations."],
    users_newpass_ok: ["La nueva contraseña para el usuario con ID", "The new password for the user with ID", "Le nouveau mot de passe de l'utilisateur avec ID"],
    users_newpass_no: ["No se ha podido actualizar la contraseña.", "Failed to update password.", "Échec de la mise à jour du mot de passe."],
    users_newuser_ok: ["Usuario creado correctamente. Se le ha enviado aviso por email.", "User created successfully. Notice has been sent to you by email.", "Utilisateur créé avec succès. Un avis vous a été envoyé par courriel."],
    users_newuser_no: ["No se pudo crear el usuario.", "Failed to create user.", "Échec de la création de l'utilisateur."],
    users_newuser_no2: ["Ocurrió un error al intentar crear el usuario.", "An error occurred while trying to create the user.", "Une erreur s'est produite lors de la tentative de création de l'utilisateur."],
    
    /* Links a los manuales */
    manual_link: ["https://docs.google.com/document/d/1P9JcQNaxFe7jJh-QCyI5zUehxyQxYNq9cDwOwJU-h1c/edit?usp=sharing", "https://docs.google.com/document/d/1PlPltaypsgwKjpSTvxkWptd8WtTxCZNVwaJUJtuWOIQ/edit?usp=sharing", "https://docs.google.com/document/d/1qYo1RI6k2_JLhnwJmU1oQBAd1iUM-n_sOZoSQXi2Hec/edit?usp=sharing"],
    

}

const lang = {

    getLabel(key) {
        if( translations[key] )
            return translations[key][userLang];
        else
            return "---";
            
    },
    getLang() {
        return userLang;
    },
    setLang( lang ) {

        userLang = lang;

        if ( localStorage )
            localStorage.setItem("exkal-lang", userLang);

    }

}

export default lang;