import storage from "./storage";

const BASE_URL = (process.env.REACT_APP_STAGE === "dev") ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_PROD_URL;

console.log( "BASE_URL 1: ", BASE_URL );

async function callApi(endpoint, options = {}) {

    options.headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+storage.token.get(),
    };

    options.cache = "no-cache";

    /*
    options.mode = 'no-cors';
    options.withCredentials = true;
    options.credentials = "include";
    options.agent = null;
    */

    const url = BASE_URL + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();

    return data;

}

function generateFilterTail( filters ) {

    // En caso de que haya filtros, vamos a componer la cola de parámetros GET
    let filterTail = "";
                    
    if ( filters.numSerie ) {   
        filterTail += '&numSerie='+filters.numSerie;
    }

    if ( filters.fechaInicio ) {   
        filterTail += '&fechaInicio='+filters.fechaInicio;
    }

    if ( filters.fechaFinal) {   
        filterTail += '&fechaFinal='+filters.fechaFinal;
    }

    if ( filters.colorDecoraciones) {   
        filterTail += '&colorDecoraciones='+filters.colorDecoraciones;
    }

    if ( filters.colorInterior) {   
        filterTail += '&colorInterior='+filters.colorInterior;
    }

    if ( filters.modelo) {   
        filterTail += '&modelo='+filters.modelo;
    }

    if ( filters.cliente) {   
        filterTail += '&cliente='+filters.cliente;
    }

    if ( filters.localizacion) {   
        filterTail += '&localizacion='+filters.localizacion;
    }

    if ( filters.refrigerante) {   
        filterTail += '&refrigerante='+filters.refrigerante;
    }

    if ( filters.accesorioEstante) {   
        filterTail += '&accesorioEstante='+filters.accesorioEstante;
    }

    if ( filters.accesorioControl) {   
        filterTail += '&accesorioControl='+filters.accesorioControl;
    }

    if ( filters.accesorioLateral) {   
        filterTail += '&accesorioLateral='+filters.accesorioLateral;
    }

    if ( filters.soloConFoto) {   
        filterTail += '&soloConFoto='+filters.soloConFoto;
    }

    return filterTail;

}

const api = {
    photos: {

        list( signal = null, filters = null, offset = null ) {

            if ( !filters ) {
                if ( !offset )
                    return callApi('/backend/photos/?list&soloConFoto=true', { signal });
                else
                    return callApi('/backend/photos/?list&soloConFoto=true&offset='+offset, { signal });
            }
                
            else {
                if ( !offset ) {
                    console.log( "Registrando acción de búsqueda!!" );
                    // En el siguiente caso añadiremos acción en el log de eventos a través del param GET "registerSearch"
                    return callApi('/backend/photos/?list'+generateFilterTail(filters)+'&registerSearch=1', { signal });
                }
                else
                    return callApi('/backend/photos/?list&offset='+offset+generateFilterTail(filters), { signal });

            }

        },

        listCancel( controller ) {
            if ( controller )
                controller.abort();
        },

        getBySerialNum( sn ) {
            return callApi('/backend/photos/?sn='+sn);
        },

        count( filters = null ) {

            if ( !filters )
                return callApi('/backend/photos/?count&soloConFoto=true');
            else {

                return callApi('/backend/photos/?count'+generateFilterTail(filters));

            }

        },

        delete( id ) {
            return callApi('/backend/photos/?id='+id, {
                method: 'DELETE',
            });
        },

        prerotate( id, angle ) {
            return callApi('/backend/photos/?photo-id='+id+'&pre-rotate='+angle, {
                method: 'GET',
            });
        },

        rotate( id, angle ) {
            return callApi('/backend/photos/?photo-id='+id+'&rotate='+angle, {
                method: 'GET',
            });
        },

        approvePending( id ) {
            return callApi('/backend/photos/?approve-pending&pending-id='+id);
        },

        updatePendingState( id ) {
            return callApi('/backend/photos/?pending-id='+id, {
                method: 'PUT',
            });
        },

        deletePending( id ) {
            return callApi('/backend/photos/?pending-id='+id, {
                method: 'DELETE',
            });
        },

        updateMain( id ) {
            return callApi('/backend/photos/?id='+id, {
                method: 'PUT',
            });
        }

    },
    pending_photos: {
        list( signal = null ) {

            return callApi('/backend/photos/?list-pending', { signal });

        },
        count() {
            
            return callApi('/backend/photos/?count-pending');

        }
    },
    users: {

        list( ) {
            return callApi('/backend/users/?list', {
                method: 'GET',
            });
        },

        getToken( data ) {
            
            return callApi('/backend/users/?token', {
                method: 'POST',
                body: JSON.stringify( data ),
            });
            
        },

        decodeToken( token ) {
            
            return callApi('/backend/users/?decoded', {
                method: 'POST',
                body: JSON.stringify( {token: token} ),
            });
            
        },

        create( email ) {
            
            return callApi('/backend/users/?email='+email, {
                method: 'POST',
            });
            
        },

        remove( id ) {
            
            return callApi('/backend/users/?id='+id, {
                method: 'DELETE',
            });
            
        },

        changePass( id ) {
            
            return callApi('/backend/users/?id='+id+'&pass', {
                method: 'PUT',
            });
            
        },

        userChangePass( user, tmp_pass, new_pass ) {
            
            return callApi('/backend/users/?user='+user, {
                method: 'PUT',
                body: JSON.stringify( {
                    tmp_pass: tmp_pass,
                    new_pass: new_pass,
                } ),
            });
            
        },

        changePermission( id, permission ) {
            
            return callApi('/backend/users/?id='+id+'&admin='+permission, {
                method: 'PUT',
            });
            
        }

    },
    info: {

        getBaseUrl(){
            return BASE_URL;
        },

        getActionLog(){
            return callApi('/backend/users/?show-log', {
                method: 'GET',
            });
        },

        getActionLogByDate( date, date2 ){
            return callApi('/backend/users/?show-log&date='+date+'&date2='+date2, {
                method: 'GET',
            });
        },

    }
};

export default api;