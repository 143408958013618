import './PendingPhotoCard.css';

import api from "../../services/api";
import {Link} from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import lang from "../../services/lang";

function PendingPhotoCard ( props ) {
    return (
        <div className={ ( (props.photo.estado == "1") && (props.showRejected == 1) ) ? "pending-photo-card hidden-card" : "pending-photo-card "}>
        
            <div className="catalog-img pending-catalog-img">
                { props.photo.path && <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/" + props.photo.path} />}
                { !props.photo.path && <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/default.png" } />}
            </div>

            <div className="catalog-info">

                <div>
                    <p><span className="catalog-info-span">{lang.getLabel("aprob_num_serie")}</span> { props.photo.serial_num ? props.photo.serial_num : "---" }</p>
                </div>

                <div className="only-desktop-block">
                    <p><span className="catalog-info-span">{lang.getLabel("aprob_usuario_propietario")}</span> { props.photo.email ? props.photo.email : "---" }</p>
                </div>

                <div className="order-card-action-btns">
                    <Button variant="contained" color="secondary" onClick={props.rotarImagen} id={props.photo.id} startIcon={<RotateRightIcon />}>
                        {lang.getLabel("aprob_rotar")}
                    </Button>
                </div>

                <div className="order-card-action-btns">
                    <Button variant="contained" color="primary" onClick={props.aprobarImagen} id={props.photo.id}>
                        {lang.getLabel("aprob_aprobar")}
                    </Button>
                </div>

                {props.photo.estado != "1" &&
                    <div className="order-card-action-btns">
                        <Button variant="contained" color="secondary" onClick={props.rechazarImagen} id={props.photo.id}>
                            {lang.getLabel("aprob_rechazar")}
                        </Button>
                    </div>
                }

                {props.photo.estado == "1" &&
                    <div className="order-card-action-btns">
                        <Button variant="contained" color={"red"} onClick={props.eliminarImagen} id={props.photo.id}>
                            {lang.getLabel("aprob_eliminar")}
                        </Button>
                    </div>
                }

            </div>

        </div>
    );
}

export default PendingPhotoCard;