import './OrderCardMini.css';

import api from "../../services/api";
import lang from "../../services/lang";
import {Link} from 'react-router-dom';

function OrderCardMini ( props ) {
    return (
        <Link to={"/order/"+props.photo.serial_num} className="order-card-mini-link" target="_blank">
            <div className="order-card-mini">
            
                <div className="catalog-mini-img">
                    { props.photo.path && <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/" + props.photo.path} />}
                    { !props.photo.path && <img alt="" src={api.info.getBaseUrl() + "/backend/photos/storage/default.png" } />}
                </div>

                <div className="catalog-mini-info-fixed">
                    <div>
                        <p><span className="catalog-info-span">{lang.getLabel("num_serie")}</span> { props.photo ? props.photo.serial_num : "---" }</p>
                    </div>
                </div>

                <div className="catalog-mini-info">

                    <div>
                        <p><span className="catalog-info-span">Cliente</span> { props.photo ? props.photo.client_id : "---" }</p>
                        <p><span className="catalog-info-span">Nº Serie</span> { props.photo ? props.photo.serial_num : "---" }</p>
                    </div>

                    <div>
                        <p><span className="catalog-info-span">Localización</span> { props.photo ? props.photo.order_location : "---" }</p>
                        <p><span className="catalog-info-span">Fecha de pedido</span> { props.photo ? props.photo.order_date : "---" }</p>
                    </div>

                    <div>
                        <p><span className="catalog-info-span">Código</span> { props.photo ? props.photo.product_code : "---" }</p>
                        <p><span className="catalog-info-span">---</span> { "---" }</p>
                    </div>

                </div>    

            </div>
        </Link>
    );
}

export default OrderCardMini;