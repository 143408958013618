// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./Action-log.css";

// Assets
import downloadIcon from "../../assets/download-icon.svg";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


class ActionLog extends React.Component {



    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            showSnackbar: false,
            snackbarMessage: "",
            date: "",
            date2: "",
        };

    }


    // Método para la carga inicial de acciones (log)
    fetchData = async ( useDates = false ) => {

        this.setState({ loading: true, error: null});

        let response = "";

        // Obtenemos resultados
        try {

            if (!useDates) {
                response = await api.info.getActionLog();
            }
            else {
                response = await api.info.getActionLogByDate( this.state.date, this.state.date2 );
            }

            // console.log("Resultados: ", response.data);
            if (response.code == 200)
                this.setState({ loading: false, error: null, data: response.data.actions });
            else
                this.setState({ loading: false, error: "Algo no salió bien. Por favor, inténtalo de nuevo.", data: [] });

        }
        catch (e) {
            this.setState({ loading: false, error: e });
        }

    }


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            this.fetchData();

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    componentWillUnmount() {
        
    }

    handleFilter = (e) => {

        // console.log( "Date 1: ", this.state.date );
        // console.log( "Date 2: ", this.state.date2 );

        // Comprobamos que fecha de final es mayor o igual a la fecha de inicio
        if ( this.state.date.length > 0 && this.state.date2.length > 0 && (this.state.date2 >=  this.state.date) ) {

            // console.log( "El rango es válido :D" );

            this.fetchData( true );

        }
        else {
            
            alert( lang.getLabel("log_error_fecha") );

        }
        
    }

    handleChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        });

    }

    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>

                    <div className="actions-details-page">

                        {!this.state.loading && this.state.data && <div id="mensaje-default" class="caja-mensaje">
                            <p>{lang.getLabel("log_default_100")}</p>
                            <p>{lang.getLabel("log_filtros_msg")}</p>
                            <div class="action-separator"></div>
                            <p>{lang.getLabel("log_msg_descarga")} <a target="_blank" href={process.env.REACT_APP_PROD_URL+'/backend/users/action-log-csv.php'} class="link-descarga"> <img src={downloadIcon} /></a></p>
                        </div>}

                        <div id="panel-filtros">
                            <TextField
                                id="date"
                                label={lang.getLabel("log_fecha_inicio")}
                                type="date"
                                name="date"
                                value={this.state.date}
                                onChange={this.handleChange}
                                // defaultValue="2017-05-24"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="date2"
                                label={lang.getLabel("log_fecha_final")}
                                type="date"
                                name="date2"
                                value={this.state.date2}
                                onChange={this.handleChange}
                                // defaultValue="2017-05-24"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <div>
                                <Button variant="contained" color="primary" onClick={this.handleFilter}>
                                    {lang.getLabel("log_fecha_btn")}
                                </Button>
                            </div>
                        </div>

                        {!this.state.loading && this.state.data && this.state.data.map((event, index) => {

                            return (

                                <div className={"action-box " + event.action }>

                                    <div><p><span className="users-page-label">{lang.getLabel("log_accion")}: </span>{event.action}</p></div>
                                    <div><p><span className="users-page-label">{lang.getLabel("log_fecha_hora")}: </span>{event.fecha_hora}</p></div>
                                    <div><p><span className="users-page-label">{lang.getLabel("log_usuario")}: </span>{event.email}</p></div>
                                    <div><p><span className="users-page-label">{lang.getLabel("log_foto_afectada")}: </span>{event.photo_id}</p></div>

                                </div>

                            );
                        })}

                        {this.state.data && this.state.data.length == 0 && <div class="caja-mensaje"><p>{lang.getLabel("log_msg_noresults")}</p></div>}

                    </div>
    
                </React.Fragment>
            );

        }
        
    }
}

export default ActionLog;
