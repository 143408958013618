import React from 'react';

import "./PanelFiltros.css";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backspace from '@material-ui/icons/Backspace';
import ClearIcon from '@material-ui/icons/Clear';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import SettingsIcon from '@material-ui/icons/Settings';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Servicios
import lang from "../../services/lang";


class PanelFiltros extends React.Component {

    // Inicializamos estado (se representan los TAGs y variables auxiliaremos del panel de filtros)
    state = {
        numSerie : "",
        fechaInicio: "",
        fechaFinal: "",
        colorDecoraciones: "",
        colorInterior: "",
        modelo: "",
        cliente: "",
        localizacion: "",
        refrigerante: "",
        accesorioEstante: "",
        accesorioControl: "",
        accesorioLateral: "",
        accesorioIluminacion: "",
        soloConFoto: true,

        showDateFields: false,
        showColorFields: false,
        showSettingsFields: false,
    }

    handleClearFilters = e => {

        this.setState({
            numSerie : "",
            fechaInicio: "",
            fechaFinal: "",
            colorDecoraciones: "",
            colorInterior: "",
            modelo: "",
            cliente: "",
            localizacion: "",
            refrigerante: "",
            accesorioEstante: "",
            accesorioControl: "",
            accesorioLateral: "",
            accesorioIluminacion: "",
            soloConFoto: true,
        });
    }

    handleChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        });

    }

    componentDidMount() {

    }

    // Método para muestra de datos
    mostrarMensaje() {
        console.log("Estado: ", this.state );
    }

    // Método de gestión del clic en el botón auxiliar de eliminación de Num Serie
    handleDeleteNumSerie = e => {
        this.setState({
            numSerie: "",
        });
    }

    handleDeleteAccesorioEstante = e => {
        this.setState({
            accesorioEstante: "",
        });
    }

    handleDeleteAccesorioControl = e => {
        this.setState({
            accesorioControl: "",
        });
    }

    handleDeleteAccesorioLateral = e => {
        this.setState({
            accesorioLateral: "",
        });
    }

    handleDeleteAccesorioIluminacion = e => {
        this.setState({
            accesorioIluminacion: "",
        });
    }

    handleDeleteClient = e => {
        this.setState({
            cliente: "",
        });
    }

    handleDeleteLocation = e => {
        this.setState({
            localizacion: "",
        });
    }

    handleDeleteModelo = e => {
        this.setState({
            modelo: "",
        });
    }

    handleShowDateFields = e => {
        this.setState({
            showDateFields: !this.state.showDateFields,
        });
    }

    handleShowColorFields = e => {
        this.setState({
            showColorFields: !this.state.showColorFields,
        });
    }

    handleShowSettingsFields = e => {
        this.setState({
            showSettingsFields: !this.state.showSettingsFields,
        });
    }

    handleOnlyWithPhoto = e => {
        this.setState({
            soloConFoto: !this.state.soloConFoto,
        });
    }


    render() {
        return (
            <React.Fragment>

                <h3 id="filter-title">
                    {lang.getLabel("f_filtros")}
                    <Tooltip title={lang.getLabel("f_reset")} arrow>
                        <IconButton
                            aria-label="Limpiar filtros"
                            onClick={this.handleClearFilters}
                            className="clear-filter-icon"
                        >
                            <ClearIcon style={{ fontSize: 15 }} />
                        </IconButton>
                    </Tooltip>
                </h3>

                <TextField
                    id="numero-serie"
                    label={lang.getLabel("f_num_serie_ref")}
                    name="numSerie"
                    value={this.state.numSerie}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Limpiar el campo"
                                        onClick={this.handleDeleteNumSerie}
                                    >
                                        {this.state.numSerie ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                    </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <div className="own-spacer-xs"></div>

                <TextField
                    id="cliente"
                    label={lang.getLabel("f_cliente")}
                    name="cliente"
                    value={this.state.cliente}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Limpiar el campo"
                                        onClick={this.handleDeleteClient}
                                    >
                                        {this.state.cliente ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                    </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <div className="own-spacer-xs"></div>

                <TextField
                    id="localizacion"
                    label={lang.getLabel("f_localizacion")}
                    name="localizacion"
                    value={this.state.localizacion}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Limpiar el campo"
                                        onClick={this.handleDeleteLocation}
                                    >
                                        {this.state.localizacion ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                    </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <div className="own-spacer-xs"></div>

                <TextField
                    id="modelo-base"
                    label={lang.getLabel("f_modelo_base")}
                    name="modelo"
                    value={this.state.modelo}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Limpiar el campo"
                                        onClick={this.handleDeleteModelo}
                                    >
                                        {this.state.modelo ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                    </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <div className="own-spacer-xs"></div>

                <FormControl>
                    <InputLabel id="refrigerante-label">{lang.getLabel("f_refrigerante")}</InputLabel>
                    <Select
                        native
                        label="Refrigerante"
                        inputProps={{
                            name: 'refrigerante',
                            id: 'refrigerante',
                        }}
                        value={this.state.refrigerante}
                        onChange={this.handleChange}
                        >
                        <option aria-label="None" value="" />
                        <option value={"Agua-Glicol"}>Agua-Glicol</option>
                        <option value={"R 290"}>R 290</option>
                        <option value={"R 134A"}>R 134A</option>
                        <option value={"R 404A"}>R 404A</option>
                        <option value={"R 407F"}>R 407F</option>
                        <option value={"R 410A"}>R 410A</option>
                        <option value={"R 427A"}>R 427A</option>
                        <option value={"R 442A"}>R 442A</option>
                        <option value={"R 448A"}>R 448A</option>
                        <option value={"R 449A"}>R 449A</option>
                        <option value={"R 450A"}>R 450A</option>
                        <option value={"R 452A"}>R 452A</option>
                        <option value={"R 507"}>R 507</option>
                        <option value={"R 513A"}>R 513A</option>
                        <option value={"R 744"}>R 744</option>
                    </Select>
                </FormControl>

                <div className="own-spacer-sm"></div>

                <div className="switch-de-campos">
                    <CalendarTodayIcon color="action" style={{ fontSize: 20, position: "relative", top: "2px" }} />
                    <span className="switch-de-campos-label">{lang.getLabel("f_fecha")}</span>
                    <IconButton aria-label="delete" size="small" onClick={this.handleShowDateFields}>
                        <ExpandMoreIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <div className={ this.state.showDateFields ? "" : "fields-hidden" }>
                    <TextField
                        id="date-in"
                        label={lang.getLabel("f_fecha_inicio")}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="fechaInicio"
                        value={this.state.fechaInicio}
                        onChange={this.handleChange}
                    />

                    <div className="own-spacer-sm"></div>

                    <TextField
                        id="date-out"
                        label={lang.getLabel("f_fecha_final")}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="fechaFinal"
                        value={this.state.fechaFinal}
                        onChange={this.handleChange}
                    />
                </div>

                <div className="own-spacer-xs"></div>

                <div className="switch-de-campos">
                    <ColorLensIcon color="action" style={{ fontSize: 20, position: "relative", top: "2px" }} />
                    <span className="switch-de-campos-label">{lang.getLabel("f_color")}</span>
                    <IconButton aria-label="delete" size="small" onClick={this.handleShowColorFields}>
                        <ExpandMoreIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <div className={ this.state.showColorFields ? "" : "fields-hidden" }>

                    <FormControl>
                        <InputLabel id="color-decoraciones-label">{lang.getLabel("f_color_decoraciones")}</InputLabel>
                        <Select
                            native
                            label="Color decoraciones"
                            inputProps={{
                                name: 'colorDecoraciones',
                                id: 'color-decoraciones',
                            }}
                            value={this.state.colorDecoraciones}
                            onChange={this.handleChange}
                            >
                            <option aria-label="None" value="" />
                            <option value={"Aluminio"}>Aluminio</option>
                            <option value={"Amarillo"}>Amarillo</option>
                            <option value={"Azul"}>Azul</option>
                            <option value={"Beige"}>Beige</option>
                            <option value={"Blanco"}>Blanco</option>
                            <option value={"Brillo"}>Brillo</option>
                            <option value={"Gris"}>Gris</option>
                            <option value={"Marron"}>Marrón</option>
                            <option value={"Marron"}>Mate</option>
                            <option value={"Negro"}>Negro</option>
                            <option value={"Oxide"}>Oxide</option>
                            <option value={"Ral"}>Ral</option>
                            <option value={"Rojo"}>Rojo</option>
                            <option value={"Verde"}>Verde</option>
                        </Select>
                    </FormControl>

                    <div className="own-spacer-xs"></div>

                    <FormControl>
                        <InputLabel id="color-interior-label">{lang.getLabel("f_color_interior")}</InputLabel>
                        <Select
                            native
                            label="Color interior"
                            inputProps={{
                                name: 'colorInterior',
                                id: 'color-interior',
                            }}
                            value={this.state.colorInterior}
                            onChange={this.handleChange}
                            >
                            <option aria-label="None" value="" />
                            <option value={"Aluminio"}>Aluminio</option>
                            <option value={"Blanco"}>Blanco</option>
                            <option value={"Brillo"}>Brillo</option>
                            <option value={"Gris"}>Gris</option>
                            <option value={"Mate"}>Mate</option>
                            <option value={"Negro"}>Negro</option>
                            <option value={"RAL 8019"}>RAL 8019</option>
                            <option value={"RAL 9003"}>RAL 9003</option>
                            <option value={"RAL 9005"}>RAL 9005</option>
                            <option value={"RAL 9011"}>RAL 9011</option>
                        </Select>
                    </FormControl>
                </div>

                <div className="switch-de-campos">
                    <SettingsIcon color="action" style={{ fontSize: 20, position: "relative", top: "2px" }} />
                    <span className="switch-de-campos-label">{lang.getLabel("f_accesorio")}</span>
                    <IconButton aria-label="delete" size="small" onClick={this.handleShowSettingsFields}>
                        <ExpandMoreIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <div className={ this.state.showSettingsFields ? "" : "fields-hidden" }>

                    <TextField
                        id="accesorio-estante"
                        label={lang.getLabel("f_accesorio_estantes")}
                        name="accesorioEstante"
                        value={this.state.accesorioEstante}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Limpiar el campo"
                                            onClick={this.handleDeleteAccesorioEstante}
                                        >
                                            {this.state.accesorioEstante ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                        </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className="own-spacer-xs"></div>

                    <TextField
                        id="accesorio-control"
                        label={lang.getLabel("f_accesorio_controles")}
                        name="accesorioControl"
                        value={this.state.accesorioControl}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Limpiar el campo"
                                            onClick={this.handleDeleteAccesorioControl}
                                        >
                                            {this.state.accesorioControl ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                        </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className="own-spacer-xs"></div>

                    <TextField
                        id="accesorio-lateral"
                        label={lang.getLabel("f_accesorio_laterales")}
                        name="accesorioLateral"
                        value={this.state.accesorioLateral}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Limpiar el campo"
                                            onClick={this.handleDeleteAccesorioLateral}
                                        >
                                            {this.state.accesorioLateral? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                        </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className="own-spacer-xs"></div>

                    <TextField
                        id="accesorio-iluminacion"
                        label={lang.getLabel("f_accesorio_iluminacion")}
                        name="accesorioIluminacion"
                        value={this.state.accesorioIluminacion}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Limpiar el campo"
                                            onClick={this.handleDeleteAccesorioIluminacion}
                                        >
                                            {this.state.accesorioIluminacion? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                        </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </div>

                <div className="own-spacer-xs"></div>

                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.soloConFoto}
                        onChange={this.handleOnlyWithPhoto}
                        name="soloConFoto"
                        color="primary"
                    />
                    }
                    label={lang.getLabel("f_solo_foto")}
                />

                <div className="own-spacer-xs"></div>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={ () => { this.props.applyFilters( this.state, true ); } }
                >
                    {lang.getLabel("f_buscar")}
                </Button>

                <div className="own-spacer-sm"></div>

            </React.Fragment>
        );
    }
}

export default PanelFiltros;