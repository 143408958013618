// REACT
import React from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

// CSS
import "./Layout.css";

// Assets
import logo from "../../assets/exkal-vectorial-w.svg";
import logo_mobile from "../../assets/e-vectorial-w.svg";
import topBarBG from "../../assets/header-no-logo.jpg";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import TranslateIcon from '@material-ui/icons/Translate';
import Badge from '@material-ui/core/Badge';


function Layout ( props ) {

    const history = useHistory();

    const switchView = () => {
        props.setView( props.view? false : true );
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
        // console.log("Avatar clicked!");
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
        // console.log("Avatar closed!");
    };

    const openManual = () => {
        setAnchorEl(null);
        window.open( lang.getLabel("manual_link") );
    };

    const goToProfile = () => {
        setAnchorEl(null);
        history.push("/user");
    };

    const goToUsersPanel = () => {
        setAnchorEl(null);
        history.push("/users");
    };

    const goToActionsPanel = () => {
        setAnchorEl(null);
        history.push("/event-log");
    };

    const logout = () => {
        setAnchorEl(null);
        storage.token.remove();
        storage.user.setName( "" );
        props.setName( "" );
        history.push("/login");
    };

    return (
        <React.Fragment>

            <div className="top-bar-wrapper">
                <div className="top-bar" style={ {backgroundImage : `url(${topBarBG})` } }>

                    <div className="top-bar-Menu">
                        <div id="menu-logo-box">
                            <Link to="/" className="link-btn" >
                                <img alt="" src={logo} />
                            </Link>
                        </div>
                        <div id="menu-logo-box-mobile">
                            <Link to="/" className="link-btn" >
                                <img alt="" src={logo_mobile} />
                            </Link>
                        </div>
                        
                    </div>

                        {
                            /*
                            (process.env.REACT_APP_STAGE === "dev") &&
                            <div id="menu-links-box">
                                <Button className="top-bar-btn" variant="contained" size="small" color="primary" onClick={ () => { console.log( storage.token.get() ); } } >Get Token</Button>
                                <Button className="top-bar-btn" variant="contained" size="small" color="primary" onClick={ () => { api.users.decodeToken( storage.token.get() ).then( (response) => { console.log( response ) } )} } >Dec Token</Button>
                            </div>
                            */
                        }

                        {storage.token.get() && 

                            <div className="top-menu-buttons">

                                {props.showViewSwitch &&
                                    <Tooltip title={lang.getLabel("cambiar_vista")} arrow>
                                        <IconButton color="secondary" aria-label="Cambiar vista" onClick={ () => { switchView(); } }>
                                            { props.view && <ViewModuleIcon />}
                                            { !props.view && <ViewStreamIcon />}
                                        </IconButton>

                                    </Tooltip>
                                }

                                {
                                    <Tooltip title={lang.getLabel("subir_foto")} arrow>
                                        <IconButton color="secondary" aria-label="Subir foto(s)" onClick={ () => { history.push("/upload"); } }>
                                            <AddAPhotoIcon />
                                        </IconButton>
                                    </Tooltip>
                                }

                                { (storage.user.getAdmin() == 0) &&
                                    <Tooltip title={lang.getLabel("pendientes_aprobacion")} arrow>
                                        <IconButton color="secondary" aria-label="Fotos pendientes de aprobación" onClick={ () => { history.push("/revision"); } }>
                                            <Badge badgeContent={props.badgeNumber} color="primary">
                                                <ThumbsUpDownIcon style={ {position: "relative", top: "4px"} }/>
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                }

                                {
                                    /*
                                    <Tooltip title={ "Cambiar idioma" } arrow>
                                        <IconButton color="secondary" aria-controls="lang-menu" aria-haspopup="true" onClick={ () => { console.log("Ea!") } }>
                                            <TranslateIcon />
                                        </IconButton>
                                    </Tooltip>
                                    
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                                        <MenuItem onClick={handleClose}>My account</MenuItem>
                                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                                    </Menu>
                                */
                                }

                            </div>

                        }

                        {storage.token.get() && 
                            <div className="top-bar-profile">
                                
                                <div id="profile-img">

                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleAvatarClick}>

                                        <div id="profile-name">
                                            { props.name }
                                        </div>
                                        <Avatar alt={ props.name } src="/static/images/avatar/1.jpg" />

                                    </Button>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleAvatarClose}
                                        elevation={5}
                                        keepMounted
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                    >
                                        <MenuItem onClick={openManual}> {lang.getLabel( "manual" )} </MenuItem>
                                        <MenuItem onClick={goToProfile}>{lang.getLabel( "perfil" )}</MenuItem>
                                        { (storage.user.getAdmin() == 0) &&
                                            <MenuItem onClick={goToUsersPanel}>{lang.getLabel( "gestionar_usuarios" )} </MenuItem>
                                        }
                                        { (storage.user.getAdmin() == 0) &&
                                            <MenuItem onClick={goToActionsPanel}>{lang.getLabel( "log_eventos" )} </MenuItem>
                                        }
                                        <MenuItem onClick={logout}>{lang.getLabel( "cerrar_sesion" )} </MenuItem>
                                    </Menu>
                                
                                </div>
                            </div>
                        }

                </div>
            </div>

            {props.children}

        </React.Fragment>
    );

}

export default Layout;