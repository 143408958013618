// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// CSS
import "./PhotoUpload.css";

// Servicios
import api from "../../services/api";
import storage from "../../services/storage";
import lang from "../../services/lang";

// Otros componentes
import Loader from '../../components/loader/Loader';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backspace from '@material-ui/icons/Backspace';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';


class UploadPage extends React.Component {



    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            data: undefined,
            numSerie : "",
            imageNames: [],
            extraImageNames: [],
            showNumError: false,
            showImageError: false,
            id: null,
        };

    }


    // Método para la carga inciial de fotos
    fetchData = async () => {

        this.setState({ loading: true, error: null });

        try {

            // let response = await api.photos.list( this.signal );
            
        }
        catch (e) {

            this.setState({ loading: false, error: e });

        }

    }


    componentDidMount() {

        // Establecemos nombre para mostrar en el Top Bar
        this.props.setName( storage.user.getName() );

        // Si disponemos de un token, realizamos la petición para obtener datos
        if ( storage.token.get() && !this.state.data) {

            // 

        }
        else {

            // Si no existe el Token redirigiremos a la página de Login
            this.props.history.push("/login");

        }

        // Obtenemos el id de la URL (si lo hubiera)
        // console.log("Parámetros en URL: ", this.props.location.search);
        let id = this.props.location.search;
        let index = id.indexOf( "id=" );
        if ( index > -1 ) {

            id = id.substring( index+3, id.length );
            // console.log("ID: ", id);
            this.setState({ numSerie: id });

        }

        // Ocultamos icono de cambio de vista
        this.props.setShowViewSwitch(false);

    }

    componentWillUnmount() {
        
    }

    handleChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        });

    }

    // Método de gestión del clic en el botón auxiliar de eliminación de Num Serie
    handleDeleteNumSerie = e => {
        this.setState({
            numSerie: "",
        });
    }

    handleImage = ( e ) => {

        let inp = document.getElementById('main-file');
        let newImagesNames = [];
        for (let i = 0; i < inp.files.length; ++i) {
            // newImagesNames.push( inp.files.item(i).name + " (" + Math.round( inp.files.item(i).size / 1000 ) + " KB)" );
            newImagesNames.push( inp.files.item(i).name );
            // console.log( inp.files.item(i) );
        }
        this.setState( { imageNames : newImagesNames } );

    }

    handleSubmit = ( e ) => {

        this.setState( { loading: true, data: null } );

        e.preventDefault();

        let valid = true;
        let inp = document.getElementById('main-file');

        // Comprobación de num serie
        if ( document.getElementById('numero-serie').value.length === 0 ) {
            valid = false;
            this.setState( { showNumError: true } );
        }
        else {
            this.setState( { showNumError: false} );
        }

        // Comprobación de existencia de imagen
        if (inp.files.length == 0 ) {
            valid = false;
            this.setState( { showImageError: true, imageErrorMessage: lang.getLabel("respuesta_minimo_una_img") } );
        }
        else {
            this.setState( { showImageError: false} );
        }

        // Comprobación de tamaños de imagen (provisionalmente desactivado)
        for (let i = 0; i < inp.files.length; ++i) {

            if( inp.files.item(i).size > 8000000 ) { // 8 MB -> límite

                valid = false;
                this.setState( { showImageError: true, imageErrorMessage: lang.getLabel("respuesta_tamano_maximo") } );
                break;

            }
            else {
                this.setState( { showImageError: false} );
            }

        }

        if (valid) {

            const data = new FormData(e.target);
    
            fetch( api.info.getBaseUrl() + '/backend/photos/upload.php', {
                method: 'POST',
                body: data,
            })
            .then(response => response.json())
            .then(data => { 
                console.log( "Response: ", data);
                if ( data.code == 200 )
                    this.setState( { loading: false, data: data, imageNames: [] } );
                else
                    this.setState( { loading: false, data: data, imageNames: [] } );
            })
            .catch( (err) => {
                console.log( "Error: ", err);
                this.setState( { loading: false, data: null, error: err, imageNames: [] } );
            });

        }
        else {
            console.log( "No válido!!" );
            this.setState( { loading: false, data: null } );
        }
            

    }

    render() {

        if (this.state.loading && (this.state.data == undefined)) {
            return (<React.Fragment> <div className="home-loader-wrapper"><Loader /></div> </React.Fragment>);
        }

        else if (this.state.error ) {
            // return `Error: ${this.state.error.message}`;
            return (<React.Fragment> <div className="errorMessageBox">{"Error: " + this.state.error.message}</div> </React.Fragment>);
        }

        else {

            return (
                <React.Fragment>

                    <div className="upload-page">

                        <h2 className="upload-page-title">{lang.getLabel("subida_titulo")}</h2>

                        <form id="image-uploader-form" onSubmit={this.handleSubmit} action={api.info.getBaseUrl() + "/backend/photos/upload.php"} method="POST" >

                            <TextField
                                id="numero-serie"
                                label={lang.getLabel("num_serie")}
                                name="numSerie"
                                value={this.state.numSerie}
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Limpiar el campo"
                                                    onClick={this.handleDeleteNumSerie}
                                                >
                                                    {this.state.numSerie ? <Backspace style={{ fontSize: 15 }} /> : <span></span>}
                                                </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            { this.state.showNumError && <div className="input-error-message">{lang.getLabel("respuesta_especificar_num_serie")}</div> }


                            <div className="own-spacer-sm"></div>

                            <Button
                                variant="contained"
                                component="label"
                                /* color="primary" */
                                >
                                <AddAPhotoIcon style={ {fontSize: "18px", marginRight: "10px"} } />
                                {lang.getLabel("subida_escoger")}
                                <input
                                    accept="image/*"
                                    hidden
                                    id="main-file"
                                    name="main-file[]"
                                    multiple
                                    type="file"
                                    onChange={this.handleImage}
                                />
                            </Button>

                            <div className="own-spacer-xs"></div>

                            <ul className="files-list-ul">
                                {
                                    this.state.imageNames.map( (image) => {
                                        return (
                                            <li>{image}</li>
                                        );
                                    })
                                }
                            </ul>

                            <div className="own-spacer-sm"></div>

                            { this.state.showImageError && <div className="input-error-message">{this.state.imageErrorMessage}</div> }

                            <div className="own-spacer-md"></div>

                            <input id="user-id" name="userID" type="hidden" value={storage.user.getUserID()}></input>

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                startIcon={<PublishIcon />}
                            >
                                {lang.getLabel("subida_publicar")}
                            </Button>
                            

                        </form>

                        { this.state.data && (this.state.data.code == 200) && (storage.user.getAdmin() == 0) &&
                            <div className="success-message">
                                <p>{lang.getLabel("respuesta_subida_ok")}</p>
                                {<p><a className="preview-link" href={"/order/"+this.state.numSerie}>{lang.getLabel("respuesta_ver_resultado")}</a></p>}
                            </div>
                        }

                        { this.state.data && (this.state.data.code == 200) && (storage.user.getAdmin() != 0) &&
                            <div className="success-message">
                                <p>{lang.getLabel("respuesta_subida_espera")}</p>
                            </div>
                        }

                        { this.state.error || (this.state.data && (this.state.data.code == 500)) &&
                            <div className="error-message">
                                <p>{lang.getLabel("respuesta_subida_no")}</p>
                                <p><b>Error: </b>{this.state.error} / {this.state.data.data.error_message}</p>
                            </div>
                        }

                    </div>
    
                </React.Fragment>
            );

        }
        
    }
}

export default UploadPage;
